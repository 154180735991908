<template>
    <div class="addSysMsg">
        <el-form ref="form" :model="messageData.instance" label-width="120px">
            <div class="choseUserCon el-form-item">
                <!-- <el-input @focus="chose"></el-input> -->
                <!-- <el-button
                    type="primary"
                    @click="chose"
                    class="el_right chose-btn"
                    size="small"
                >
                    选择人员
                </el-button> -->
                <label class="el-form-item__label" @click="chose"><a href="javascript:;">选择人员</a></label>
                <ul class="selectedUsers el-form-item__content">
                    <li v-for="item in messageData.receivers" :key="item.userid">
                        <span>{{ item.username }}</span>
                        <a @click="delUser(item)">×</a>
                    </li>
                </ul>
            </div>
            <el-form-item label="消息标题">
                <el-input v-model="messageData.instance.title"></el-input>
            </el-form-item>
            <el-form-item label="消息内容" class="editor">
                <!-- v-model="messageData.instance.content" -->
                <wangEditor
                    :is-clear="isClear"
                    @change="editorChange"
                    :guid="guid"
                ></wangEditor>
            </el-form-item>
            <el-form-item label="附件">
                <el-button title="附件上传">
                    <el-upload
                        class="uploadfile"
                        action=""
                        :http-request="upFun"
                        :show-file-list="false"
                        multiple
                    >
                        <span>附件上传</span>
                    </el-upload>
                </el-button>
            </el-form-item>
            <el-form-item label="" v-if="fileList.length>0" class="uploadListCon">
                <ul class="uploadList">
                    <li v-for="item in fileList" :key="item.uid">
                        <span class="iconfont iconwenjian"></span>
                        <span>{{ item.name }}</span>
                    </li>
                </ul>
            </el-form-item>
            <!-- <el-form-item>
                <el-button type="primary" @click="onSubmit">
                    发布
                </el-button>
                <el-button @click="close">
                    取消
                </el-button>
            </el-form-item> -->
        </el-form>
        <div class="btn-con">
            <el-button @click="close" size="small">
                取消
            </el-button>
            <el-button type="primary" size="small" @click="onSubmit">
                发布
            </el-button>
        </div>
        <choseMembers
            :show-dialogue="choseMemberD.show"
            :type="choseMemberD.type"
            :dialogue-tit="choseMemberD.tit"
            @update-show="updateShowDialogue"
            @sure-members="sureMembers"
            :chosed-members="messageData.receivers"
        ></choseMembers>
    </div>
</template>
<script>
import wangEditor from './wangEditor';
import choseMembers from './sysChoseMembers';
import utils from '@/utils/util';
export default {
    components: { wangEditor, choseMembers },
    props: {},
    data() {
        return {
            aliyunOssToken: null,
            buCode: 'SystemMessage',
            form: {
            },
            choseMemberD: {
                show: false,
                type: '',
                tit: '选取人员',
            },
            messageData: {
                instance: {
                    type: 1,
                    content: '',
                },
                receivers: [],
                events: [],
                enclosures: [],
            },
            isClear: false,
            guid: '',
            fileList: [],
        };
    },
    computed: {},
    methods: {
        close() {
            this.$parent.$emit('close');
        },
        chose() {
            this.choseMemberD.show = true;
        },
        updateShowDialogue(val) {
            this.choseMemberD.show = val;
        },
        editorChange(val) {
            this.messageData.instance.content = val;
        },
        onSubmit() {
            this.addMessage();
        },
        // 选取人员完成后回调
        sureMembers(data) {
            const rows = data.data.map(v => {
                return {
                    userid: v.user_id,
                    username: v.user_name,
                };
            });
            this.messageData.receivers = this.messageData.receivers.concat(rows);
        },
        // 删除选取的人员
        delUser(data) {
            const arr = this.messageData.receivers;
            arr.splice(arr.findIndex(item => item.userid === data.userid), 1);
            this.messageData.receivers = arr;
        },
        // 发布系统消息接口
        addMessage() {
            this.$axios
                .post('/interfaceApi/message/MessageInfo/add_message', this.messageData)
                .then(res => {
                    this.$message.success(res);
                    this.$parent.$emit('close');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 上传附件
        uploadFileMethod(param) {
            const fileStage = param.file;
            const formData = new FormData();
            formData.append('files', param.file);
            formData.append('code', 'SystemMessage');
            formData.append('data_id', this.guid);
            formData.append('group_id', this.guid);
            this.$axios
                .post(this.FILE.FILE_BASE_URL() + 'file/uploadfile', formData)
                .then(res => {
                    if (res) {
                        this.fileList.push(fileStage);
                        this.messageData.enclosures.push({ filename: fileStage.name, filesize: fileStage.size, keyid: res[0] });
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
        },
        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + 'credential/GetPostPolicy/' + this.buCode).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                }
            });
        },
        // 上传
        upFun(val) {
            const filename = val.name;
            // 判断是否过期，过期重新调用获取通信证方法
            const formData = new FormData();
            // 注意formData里append添加的键的大小写
            formData.append('key', `${this.aliyunOssToken.dir}${this.guid}/${filename}`); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
            formData.append('policy', this.aliyunOssToken.policy); // policy
            formData.append('Signature', this.aliyunOssToken.signature); // 签名
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('file', val);
            // formData.append('FileDescription', this.newFile.FileDescription);
            formData.append('success_action_status', 200); // 成功后返回的操作码
            formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

            // 回调参数
            const obj = {
                bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                business_type_code: this.buCode, // 业务类型编码
                object_name: `${this.aliyunOssToken.dir}${this.dataInfo.quality.id}/${filename}`, // 文件对象名
                show_name: filename, // 显示文件名
                size: 0, // 文件大小
                mimeType: '',
                mime_type: val ? val.raw : '',
                data_id: this.guid, // 业务数据id
                directory_id: '', // 目录id
                tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                // FileDescription: this.newFile.FileDescription, // 文件描述
            };
            this.uploadFun(formData, obj, val.file);
        },
        // 上传文件
        uploadFun(formData, val, fileStage) {
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: data => { // 成功回调
                    this.callBackFun(val, fileStage);
                },
            });
        },
        // 上传的回调
        callBackFun(obj, fileStage) {
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                this.formData.Sign_Picture = res.id;
                this.fileList.push(fileStage);
                this.messageData.enclosures.push({ filename: fileStage.name, filesize: fileStage.size, keyid: res.id });
            });
        },
    },
    created() {
        this.guid = utils.getGuid();
        this.getOssToken();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.addSysMsg
    height 100%
    overflow hidden
    .el-form
        width 100%
        height calc(100% - 50px)
        overflow-y auto
        overflow-x hidden
        .el-form-item__label
            width 1.2rem!important
    .el-form-item__label
        line-height .4rem
    .el-button
        padding .08rem .20rem
        font-size .14rem
    .btn-con
        text-align center
        position absolute
        bottom .2rem
        width 100%
    .el-form-item__content
        line-height .36rem
        height .36rem
    .el-tree-node__content
        height .30rem
    .el-form-item__content
        width: calc(100% - 1.35rem);
        margin-left: 1.2rem!important;
    .editor
        margin-bottom 22px
        .el-form-item__content
            line-height auto
            height auto
    .uploadListCon
        height 1rem
        overflow-y auto
        overflow-x hidden
        .uploadList
            display inline-block
            width calc(100% - .20rem)
            li
                font-size .15rem
                .iconfont
                    font-size 0.16rem
                    color #1296db
                    margin-right .10rem
                    line-height .16rem
                border-bottom 1px solid #eee
    .choseUserCon
        position relative
        .chose-btn
            width: .8rem;
            text-align: center;
            padding 0
            height .36rem
            line-height .32rem
        .el-form-item__label
            a
                color #1577D2
                text-decoration underline
        .el-form-item__content
            min-height .36rem
            height auto
        .selectedUsers
            background #fff
            z-index 10
            border 1px solid #DCDFE6
            height 100%
            overflow-y auto
            box-shadow 0 0 0.01rem
            border-radius: 2px;
            padding .1rem
            min-height .36rem
            max-height 1.6rem
            li
                float left
                list-style none
                border-width 1px
                border-style solid
                border-color rgb(202, 216, 243)
                // border-image initial
                border-radius .06rem
                font .16rem/.28rem '微软雅黑'
                padding 0 .08rem
                background rgb(222, 231, 248)
                margin 0.01rem 0.05rem 0 0
                height 0.3rem
                line-height 0.26rem
                margin-bottom .1rem
                a
                    margin-left .05rem
                    font-size .20rem
                    color #777
                    cursor pointer
                &:hover
                    a
                        color #f56c6c
</style>